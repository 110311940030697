*{
    padding: 0;
    margin: 0;
    font-family: 'Peppins', sans-serif;
    box-sizing: border-box;
    transition: all .2s linear;
    scroll-behavior: smooth;
}

@media only screen and (max-width: 1080px){
    .App{
        overflow-x: hidden;
    }
}